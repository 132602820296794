import React, { useState, useEffect } from 'react';
import { getVersion } from '../../../../endpoints/app';

const Footer = () => {
    const [currentVersion, setCurrentVersion] = useState(undefined);

    useEffect(() => {
        getVersion().then(data => setCurrentVersion(data.version))
    }, [])

    return <div className="credit" >
        <small className="credit__developer">App by Ecreatures</small>
        <small>{currentVersion}</small>
    </div >
}

export default Footer;