import { ENTER_PRIVATE_TCHAT, LOAD_FIRST_TIME, OPEN_MENU, SET_NOTIFICATION } from '../action/tchat/tchat_types'

const tchat = (state = { data: { userConversation: undefined, count: 0, notification: { unread: 0, data: [] } } }, action) => {
    switch (action.type) {
        case ENTER_PRIVATE_TCHAT:
            return {
                ...state,
                data: {
                    ...state.data,
                    userConversation: action.payload.userConversation,
                    enableWebcamCall: action.payload.enableWebcamCall
                }
            }

        case OPEN_MENU:
            return {
                ...state,
                data: { ...state.data, menuOpened: action.payload.menuOpened }
            }

        case LOAD_FIRST_TIME:
            return {
                ...state,
                data: {
                    ...state.data,
                    notification: false,
                    message: false
                }
            }

        case SET_NOTIFICATION:
            return {
                ...state,
                data: {
                    ...state.data,
                    notification: {
                        unread: action.payload.count,
                        data: action.payload.data.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
                    }
                }
            }

        default:
            return state
    }
}

export { tchat }