import React, { useState } from 'react';
import { Avatar, Button, Collapse } from 'antd';
import './notification.css';
import { connect } from 'react-redux';
import { getNotification, markAsReadNotification } from '../../../endpoints';
import { EyeFilled, SearchOutlined, SyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { setNotification } from '../../../action/tchat/tchat_actions';

const { Panel } = Collapse;

const Notification = ({ user, tchat, app, ...props }) => {
    const [changeElement, setChangeElement] = useState(['1']);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpenNotification = (id) => {
        if (typeof id === 'undefined') return;
        return markAsReadNotification(id)
            .then(async () => {
                let __tmp = await tchat?.data?.notification?.data;
                for (let i = 0; i < __tmp.length; i++) {
                    if (__tmp[i]?._id === id) {
                        __tmp[i].unread = false
                    }

                    if (i + 1 === __tmp.length) {
                        props.dispatch(setNotification({ count: tchat?.data?.notification?.unread - 1, data: [...__tmp] }))
                    }
                }
            })
    }

    const searchMore = async type => {
        if (type === 'notification') {
            setLoading(true);
            await getNotification({ receiver: user?.data?.id, skipNumber: tchat?.data?.notification?.data.length })
                .then(result => {
                    if (result?.data.length < 8) {
                        setDisable(true);
                        setLoading(false);
                        props.dispatch(setNotification({ count: tchat?.data?.notification?.unread, data: [...tchat?.data?.notification?.data, ...result?.data] }))
                    } else {
                        setDisable(false);
                        setLoading(false);
                        props.dispatch(setNotification({ count: tchat?.data?.notification?.unread, data: [...tchat?.data?.notification?.data, ...result?.data] }))
                    }
                }
                )
        }
    }

    return (<Collapse className="element-collapsed-notification" activeKey={changeElement} onChange={e => e.length > 1 ? setChangeElement(e.splice(1)) : setChangeElement(e)}>
        <Panel id="notification-scroll" header={`Notification ${tchat?.data?.notification?.unread ? `(${tchat?.data?.notification?.unread})` : ''}`} key="1" className="notification-list">
            {tchat?.data?.notification?.data.length > 0 ? (tchat?.data?.notification?.data?.map(n => (
                <div className={`notification-item ${n?.unread ? 'unread' : ''}`} key={n?._id} onClick={() => handleOpenNotification(n?.unread ? n?._id : undefined)}>
                    <div className="avatar-notification">
                        <Avatar size="larger" style={{ background: 'rgb(0, 21, 41)', textTransform: "uppercase" }}>
                            S
                        </Avatar>
                        {n?.description ? <div className="update-icon-notification">
                            <SyncOutlined />
                        </div> : <div className="user-icon-notification">
                            <UserAddOutlined />
                        </div>}
                    </div>
                    <div className="detail-notification">
                        <small className="title-notification">
                            {n?.title}
                        </small>
                        <small className="date-notification">{new Date(n?.timestamp).toLocaleDateString('fr', { hour: 'numeric', minute: 'numeric' })}</small>
                    </div>
                    {n?.description && <div className="view-changelog">
                        <Button size="small" icon={<EyeFilled />} type="text" />
                    </div>}
                </div>
            ))) : (<div className="notification-item">
                <small className="title-notification">
                    Vous n'avez aucune notification
                </small>
            </div>)}
            {tchat?.data?.notification?.data.length >= 8 && <div className="load-more">
                <Button size="small" loading={loading} type="primary" disabled={disable} onClick={() => searchMore('notification')} icon={<SearchOutlined />}>Voir plus loin</Button>
            </div>}
        </Panel>
    </Collapse>)
}

const mapStateToProps = ({ user, tchat, app }) => ({ user, tchat, app })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Notification);