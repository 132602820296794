import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Form, Button, Input } from 'antd';
import { Helmet } from "react-helmet";
import './Login.css'
import { setLogin } from '../../action/authentication/authentication_actions';
import { doLogin, getNotification } from '../../endpoints';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setNotification } from '../../action/tchat/tchat_actions';
import { store } from '../..';
import './StarsComponent/stars.css';
import Stars from './StarsComponent';

const Login = ({ user, tchat, app, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [socket, setSocket] = useState(null)
    const [form] = Form.useForm();
    const StarsAnimation = new Stars();
    const pseudoElement = document.getElementById('pseudo');
    const passElement = document.getElementById('password');

    useEffect(() => {
        setSocket(true);
        typeof window.socket.id === 'undefined' && window.socket.connect()
        StarsAnimation.outputStars();
        // eslint-disable-next-line
    }, [])

    const onFinish = values => {
        setIsLoading(true);

        doLogin({ pseudo: pseudoElement.getAttribute('data-pseudo') || values.pseudo, password: passElement.getAttribute('data-pass') || values.password, socketId: window.socket.id })
            .then(async (data) => {
                const { error_exception } = data;
                if (error_exception) {
                    setIsLoading(false);
                    form.setFields([
                        {
                            name: 'pseudo',
                            errors: [error_exception]
                        }
                    ])
                }
                if (!error_exception && !user?.data?.id) {
                    await getNotification({ receiver: data?._id })
                        .then(result => props.dispatch(setNotification({ count: result?.count, data: result?.data })))
                    window.socket.on('request-notification', async (data) => {
                        if (store?.getState()?.tchat?.data?.notification?.data) {
                            let tmpNotification = store?.getState()?.tchat?.data?.notification?.data;
                            await tmpNotification.push({ ...data })
                            props.dispatch(setNotification({ count: store?.getState()?.tchat?.data?.notification?.unread + 1, data: [...tmpNotification] }))
                        }
                    })
                    await props.dispatch(setLogin({
                        pseudo: data?.pseudo,
                        statusOnline: data?.statusOnline,
                        id: data?._id,
                        socketId: window.socket.id,
                        defaultColor: `${data?.defaultColor?.r},${data?.defaultColor?.g},${data?.defaultColor.b},${data?.defaultColor?.a}`,
                        email: data?.email,
                        registerDate: data?.registerDate,
                        source_lang: 'FR',
                        ...(data.avatar && { avatar: data.avatar })
                    }));
                    await window.socket.emit('users', {
                        pseudo: data?.pseudo,
                        statusOnline: data?.statusOnline,
                        id: data?._id,
                        socketId: window.socket.id,
                        source_lang: 'FR'
                    });
                    setIsLoading(false);
                    props.history.push('/global')
                }
            })
            .catch(err => { setIsLoading(false) })
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Connexion</title>
        </Helmet>
        <div className="layout-login">
            <div id="stars"></div>
            <div className="layout-content">
                <div className="picture-form">
                    <img src="https://chatelainxpert.be/img/logo.svg" alt="Logo chatelainxpert" />
                </div>
                <small className="title-form">Messagerie instantanée innovante avec traduction en temp réel</small>
                <Form className="layout-login-form" onFinish={onFinish} form={form} style={{ display: 'flex' }}>
                    <Form.Item name="pseudo" rules={[{ required: false, message: "Le pseudo n'est pas valide" }]} >
                        <Input prefix={<UserOutlined />} name="pseudo-line" defaultValue={pseudoElement?.getAttribute('data-pseudo')} type="text" autoFocus placeholder="Entrez votre pseudo" allowClear={true} />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: false, message: "Le mot de passe n'est pas valide" }]} >
                        <Input prefix={<LockOutlined />} name="password-line" defaultValue={passElement?.getAttribute('data-pass')} type="password" autoFocus placeholder="Entrez votre mot de passe" allowClear={true} />
                    </Form.Item>
                    <Button htmlType="submit" disabled={!socket} loading={isLoading} type="primary">Connexion</Button>
                    <div>
                        {/* eslint-disable-next-line */}
                        <span className="title-form"><small>Problème de connexion ? <a href="" onClick={() => props.history.push('/recovered')}>Réinitialiser mot de passe</a></small></span>
                    </div>
                    <div>
                        {/* eslint-disable-next-line */}
                        <span className="title-form"><small>Pas encore membre ? <a href="" onClick={() => props.history.push('/register')}>Créer mon compte</a></small></span>
                    </div>
                </Form>
            </div>
        </div>
    </>)
}

const mapStateToProps = ({ user, tchat, app }) => ({ user, tchat, app });
const mapDispatchToProps = dispatch => ({ dispatch })

export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Login);