import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Layout, Button, Tooltip, Input, Drawer } from 'antd'
import _ from 'underscore'
import './SiderComponent.css'
import './SiderComponentMobile.css'
import { getCountUsersConnected } from '../../../endpoints';
import { CommentOutlined, MessageOutlined } from '@ant-design/icons';
import { setEnterPrivateTchat, setOpenMenu } from '../../../action/tchat/tchat_actions';
import { store } from '../../../index'
import Footer from './Footer/Footer';
import { Notification } from '../../Notification';

const SiderComponent = ({ user, tchat, viewTchat, isMobile, onSelectUser, ...props }) => {
    const [users, setUsers] = useState([{}])
    const [filterUser, setFilterUser] = useState([])
    const [listen, setListen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [listenStatus, setListenStatus] = useState(false)

    const goToPrivate = (id, e) => {
        const getUserElement = document.getElementById(id);
        getUserElement && getUserElement.classList.remove('incomming-message')
        props.dispatch(setEnterPrivateTchat({ userConversation: id }))
        document.title = `tchatez - ${user.data?.name}`
        onSelectUser({ pseudo: e.data?.pseudo, target_lang: e?.data?.target_lang })
        console.log('user *** ',e)
        window.socket.emit('send-negociation', { socketId: id, adminSocketId: window.socket.id, pseudo: user.data?.name, id: user?.data?.id, target_lang: e?.data?.target_lang, source_lang: 'FR' })
        props.history.push(`/conversation/${e?.data?.id}`, { socketId: id })
    }

    useEffect(() => {
        getCountUsersConnected({ socketId: user?.data?.id }).then(data => {
            if (data) {
                setUsers(data.users.filter(e => e.id !== window.socket.id))
            }
        })
    }, [user])

    useEffect(() => {
        !listenStatus && window.socket.on('users-status', data => {
            setUsers(data.users.filter(e => e.id !== window.socket.id))
            setFilterUser(data.users.filter(e => e.id !== window.socket.id))
            setListenStatus(true)
        })
    }, [listenStatus])

    useEffect(() => {
        !listen && window.socket.on('users-online', (data) => {
            if (data?.users) {
                setUsers(data.users)
                setFilterUser(data.users)
            }
        })

        !listen && window.socket.on('receive-message', data => {
            const { tchat, user } = store.getState()
            if (user.data?.statusOnline === "online"
                && (data?.socketId === window.socket.id)) {
                if (tchat.data.userConversation !== data.socketIdDestination) {
                    const getUserElement = document.getElementById(data?.socketIdDestination);
                    getUserElement && getUserElement.classList.add('incomming-message')
                    return Notification(data, goToPrivate, user)
                }
            }
        })

        setListen(true)
        // eslint-disable-next-line
    }, [tchat, listen, filterUser])

    const handleSearch = value => {
        setSearchQuery(value?.currentTarget?.value.toLowerCase())
    }

    return (
        <Layout>
            <Layout.Sider className="sider-users-connected" id="button-mobile">
                {isMobile ? <Drawer className="sider-mobile" title="WeWorldChat" placement="left" closable key="1" visible={tchat?.data?.menuOpened} onClose={() => props.dispatch(setOpenMenu({ menuOpened: false }))}>
                    <div className="flex-container">
                        <ul className="list-users-connected">
                            <div className="search-containter">
                                <Input placeholder='Rechercher un utilisateur'
                                    className="search-users"
                                    onChange={handleSearch}
                                    type="text"
                                ></Input>
                            </div>
                            <div className="item__user">
                                {typeof users !== 'undefined' && (searchQuery.length > 0 ? users.filter(el => typeof el?.data?.pseudo.toLowerCase().match(searchQuery) !== 'undefined' && typeof el?.data?.pseudo.toLowerCase().match(searchQuery)?.input !== 'undefined' && el?.data?.pseudo.toLowerCase() === el?.data?.pseudo.toLowerCase().match(searchQuery).input) : users).map((el, index) => (
                                    <>{
                                        el?.data?.id !== user.data?.id && (
                                            <li key={index} id={el?.data?.id} className={`item-user ${el.id === tchat.data?.userConversation ? 'selected' : ''}`}>
                                                <Tooltip title={`${el.data?.pseudo} - ${el?.data?.alreadyInChat ? 'Déjà en communication' : el.data?.statusOnline === 'busy' ? 'occupé' : 'en ligne'}`} placement={el.data?.pseudo.length < 8 ? 'topRight' : 'top'}>
                                                    <div className="info-user">
                                                        <div className={`status__online__${el.data?.statusOnline === 'online' ? 'online' : 'busy'}`} />
                                                        &nbsp;
                                                        <span onClick={() => el?.data?.alreadyInChat ? (el?.data?.alreadyInChat !== false && el?.data?.alreadyInChat === user?.data?.id && goToPrivate(el?.id, el)) : false}>{el.data?.pseudo}</span>
                                                    </div>
                                                </Tooltip>
                                                {el?.data?.id !== user.data?.id && <Button size="small" disabled={el.id === tchat.data?.userConversation || el?.data?.alreadyInChat ? (el?.data?.alreadyInChat !== false && el?.data?.alreadyInChat !== user?.data?.id) : false} onClick={() => goToPrivate(el.id, el)}>{!el?.data?.alreadyInChat ? <MessageOutlined /> : <CommentOutlined />}</Button>}
                                            </li>
                                        )
                                    }</>
                                ))}
                            </div>
                        </ul>
                        <Footer />
                    </div></Drawer> : <div className="flex-container">
                    <ul className="list-users-connected">
                        <div className="search-containter">
                            <Input placeholder='Rechercher un utilisateur'
                                className="search-users"
                                onChange={handleSearch}
                                type="text"
                            ></Input>
                        </div>
                        <div className="item__user">
                            {typeof users !== 'undefined' && (searchQuery.length > 0 ? users.filter(el => typeof el?.data?.pseudo.toLowerCase().match(searchQuery) !== 'undefined' && typeof el?.data?.pseudo.toLowerCase().match(searchQuery)?.input !== 'undefined' && el?.data?.pseudo.toLowerCase() === el?.data?.pseudo.toLowerCase().match(searchQuery).input) : users).map((el, index) => (
                                <>{
                                    el?.data?.id !== user.data?.id && (
                                        <li key={index} id={el?.data?.id} className={`item-user ${el?.id === tchat.data?.userConversation ? 'selected' : ''}`}>
                                            <Tooltip title={`${el.data?.pseudo} - ${el?.data?.alreadyInChat ? 'Déjà en communication' : el.data?.statusOnline === 'busy' ? 'occupé' : 'en ligne'}`} placement={el.data?.pseudo.length < 8 ? 'topRight' : 'top'}>
                                                <div className="info-user">
                                                    <div className={`status__online__${el.data?.statusOnline === 'online' ? 'online' : 'busy'}`} />
                                                    &nbsp;
                                                    <span onClick={() => el?.data?.alreadyInChat ? (el?.data?.alreadyInChat !== false && el?.data?.alreadyInChat === user?.data?.id && goToPrivate(el?.id, el)) : false}>{el.data?.pseudo}</span>
                                                </div>
                                            </Tooltip>
                                            {el?.data?.id !== user.data?.id && <Button size="small" disabled={el.id === tchat.data?.userConversation || el?.data?.alreadyInChat ? (el?.data?.alreadyInChat !== false && el?.data?.alreadyInChat !== user?.data?.id) : false} onClick={() => goToPrivate(el?.id, el)}>{!el?.data?.alreadyInChat ? <MessageOutlined /> : <CommentOutlined />}</Button>}
                                        </li>
                                    )
                                }</>
                            ))}
                        </div>
                    </ul>
                    <Footer />
                </div>}
            </Layout.Sider>
        </Layout >)
}

const mapStateToProps = ({ user, tchat }) => ({ user, tchat })
const mapDispatchToProps = dispatch => ({ dispatch })

export default _.compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(SiderComponent)